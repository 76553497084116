import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Day from './day';

const staging = process.env.REACT_APP_STAGING === 'true';

const useStyles = makeStyles((theme) => ({
    calendar: (props) => ({
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gridAutoRows: `calc(${props.height}px / 5)`, // assuming 5 rows for a month
        gap: theme.spacing(1),
        width: props.width,
        height: props.height,
        padding: theme.spacing(1),
        alignItems: 'stretch',
        justifyItems: 'stretch',
    }),
}));

const Calendar = ({width, height, subscriptions}) => {
    if (staging) {
        console.log("subscriptions passed to Calendar component", subscriptions);
    }
    const classes = useStyles({width, height});
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    const calendarDays = Array(firstDayOfMonth).fill(null).concat(days);

    return (
        <div className={classes.calendar}>
            {calendarDays.map((day, index) => {
                const daySubscriptions = subscriptions.filter(subscription => {
                    const subscriptionDate = new Date(subscription.formattedDate);
                    const subscriptionDay = subscriptionDate.getDate();
                    const subscriptionMonth = subscriptionDate.getMonth() + 1; // Adjust month to 1-12 range
                    const isYearly = subscription.subscriptionFrequency === 'Yearly';
                    const currentMonth = currentDate.getMonth() + 1; // Adjust month to 1-12 range
                    return subscriptionDay === day && (!isYearly || subscriptionMonth === currentMonth);
                });
                if (staging) {
                    console.log("day ", day, "daySubscriptions ", daySubscriptions);
                }
                return (
                    <Day key={index} day={day} isToday={day === currentDay} subscriptions={daySubscriptions} />
                );
            })}
        </div>
    );
};

export default Calendar;