import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    day: (props) => ({
        border: props.day ? (props.isToday ? '2px solid blue' : '1px solid gray') : 'none',
        borderRadius: '10px',
        position: 'relative',
        padding: theme.spacing(1),
        overflow: 'hidden',
    }),
    dayNumber: (props) => ({
        position: 'absolute',
        top: theme.spacing(1),
        left: theme.spacing(1),
        fontSize: '1em',
        color: props.isToday ? 'blue' : 'gray',
    }),
    subscriptionContainer: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        //border: '1px solid gray',
        //borderRadius: '10px',
        overflow: 'auto',
    },
    subscription: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2),
        fontWeight: 'bold',
        fontSize: '1em',
        color: 'black',
    },
}));

const Day = ({ day, isToday, subscriptions }) => {
    const classes = useStyles({ day, isToday });

    return (
        <div className={classes.day}>
            <div className={classes.dayNumber}>
                {day}
            </div>
            <div className={classes.subscriptionContainer}>
                {subscriptions.map((subscription, index) => (
                    <div key={index} className={classes.subscription}>
                        <span>{subscription.subscriptionName}</span>
                        <span>{subscription.subscriptionCost && `$${subscription.subscriptionCost}`}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Day;