import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LeftNavBar from '../components/leftNavBar';
import SubscriptionBar from '../components/subscription'; // Import the SubscriptionBar component
import { makeStyles } from '@material-ui/core/styles'; // Import makeStyles

const baseUrl = process.env.REACT_APP_API_GATEWAY_URL;
const staging = process.env.REACT_APP_STAGING === 'true';

const useStyles = makeStyles((theme) => ({
    // ...
    totalCost: {
        fontSize: '1.75em',
        color: 'black',
        textAlign: 'right',
        fontWeight: 'bold',
        marginRight: '30px',
        marginTop: '10px',
    },
}));

const MySubscriptions = ({ user }) => {
    const classes = useStyles(); // Call useStyles to get classes
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        axios.get(`${baseUrl}/subscriptions/?userId=${user.sub}`)
            .then(res => {
                if (staging) {
                    console.log(res.data);
                }
                const subscriptionsWithFormattedDate = res.data.map(subscription => {
                    const date = new Date(subscription.subscriptionDate);
                    const formattedDate = `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}`;
                    // let formattedDate;
                    
                    // if (subscription.subscriptionFrequency === "Yearly") {
                    //     formattedDate = `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}`;
                    // } else if (subscription.subscriptionFrequency === "Monthly") {
                    //     const currentDate = new Date();
                    //     formattedDate = `${currentDate.toLocaleString('default', { month: 'long' })} ${date.getDate()}`;
                    // }
    
                    return { ...subscription, formattedDate };
                });
    
                const sortedSubscriptions = subscriptionsWithFormattedDate.sort((a, b) => {
                    return new Date(a.formattedDate) - new Date(b.formattedDate);
                });
    
                setSubscriptions(sortedSubscriptions);
            })
            .catch(err => {
                console.log(err);
            });
    }, [user.sub]);

    const calculateYearlyCost = (cost, frequency) => {
        cost = Number(cost); // Ensure cost is a number
        switch (frequency) {
            case 'Monthly':
                return cost * 12;
            case 'Quarterly':
                return cost * 4;
            case 'Yearly':
                return cost;
            default:
                return 0;
        }
    };

    const totalYearlyCost = subscriptions.reduce((total, subscription) => {
        return total + calculateYearlyCost(subscription.subscriptionCost, subscription.subscriptionFrequency);
    }, 0);

    return (
        <div style={{ display: 'flex' }}>
            <LeftNavBar />
            <div style={{ display: 'flex', width: '100%' ,flexDirection: 'column', margin: '10px' }}>
                {subscriptions.map((subscription, index) => (
                    <SubscriptionBar 
                        key={index} 
                        subscriptionName={subscription.subscriptionName} 
                        subscriptionDate={subscription.subscriptionDate} 
                        formattedDate={subscription.formattedDate}
                        subscriptionId={subscription._id}
                        subscriptionFrequency={subscription.subscriptionFrequency}
                        subscriptionCost={subscription.subscriptionCost}
                    />
                ))}
                <div className={classes.totalCost}>Total Yearly Cost: ${totalYearlyCost.toFixed(2)}</div>
            </div>
            {/* Rest of the view here */}
        </div>
    );
}

export default MySubscriptions;