import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LeftNavBar from '../components/leftNavBar';
import { Box, makeStyles } from '@material-ui/core';

const baseUrl = process.env.REACT_APP_API_GATEWAY_URL;
const staging = process.env.REACT_APP_STAGING === 'true';

const useStyles = makeStyles((theme) => ({
    box: {
        border: '2px solid gray',
        borderRadius: '10px',
        padding: theme.spacing(1),
        margin: theme.spacing(2),
        minWidth: '15%',
    },
    imageBox: {
        border: '2px solid gray',
        borderRadius: '10px',
        padding: theme.spacing(1),
        margin: theme.spacing(2),
        minWidth: '15%',
        maxHeight: '50%',
    },
    item: {
        border: '1px solid gray',
        borderRadius: '10px',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '1.25em',
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        '&:hover': {
            borderColor: '#006dd1', // blue
        },
    },
}));

const FindServices = ({ user }) => {
    const classes = useStyles();
    const [categories, setCategories] = useState([]);
    const [services, setServices] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedService, setSelectedService] = useState(null);

    useEffect(() => {
        axios.get(`${baseUrl}/services/categories/`)
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('Error fetching categories', error);
            });
    }, []);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        axios.get(`${baseUrl}/services/services/${category}`)
            .then(response => {
                setServices(response.data);
            })
            .catch(error => {
                console.error(`Error fetching services for category ${category}`, error);
            });
    };

    const handleServiceClick = (service) => {
        if (staging) {
            console.log(service);
        }
        setSelectedService(service);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <LeftNavBar />
            <Box className={classes.box}>
                {categories.map(category => (
                    <Box key={category} className={classes.item} onClick={() => handleCategoryClick(category)}>
                        {category}
                    </Box>
                ))}
            </Box>
            {selectedCategory && (
                <Box className={classes.box}>
                    {services.map(service => (
                        <Box key={service.name} className={classes.item} onClick={() => handleServiceClick(service)}>
                            {service.name}
                        </Box>
                    ))}
                </Box>
            )}
            {selectedService && (
                <Box className={classes.imageBox} style={{ flex: 1, overflow: 'auto' }}>
                    <a href={selectedService.link} target="_blank" rel="noreferrer" style={{ display: 'block', width: '100%' }}>
                        <img 
                            src={selectedService.logo} 
                            alt={selectedService.name} 
                            style={{ width: '100%', height: 'auto', maxHeight: '50vh' }}
                        />
                    </a>
                    <p style={{ textAlign: 'center' }}>Click the image to go to {selectedService.name}</p>
                </Box>
            )}
        </div>
    );
};

export default FindServices;