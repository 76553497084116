import { makeStyles } from '@material-ui/core/styles';

const navColor = '#222222'; // Set the color to your desired color (default is #0565C7)

const useStyles = makeStyles((theme) => ({
    
    topNavBar: {
        '&.MuiAppBar-root': {
            backgroundColor: navColor, // replace #yourColor with your desired color
            height: '64px', // Adjust the height as needed
        },
    },
    logo: {
        marginRight: '0px', // Adjust the margin as needed
        width: '60px', // Adjust the width as needed
    },
    leftNavBar: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        backgroundColor: navColor, // replace #yourColor with your desired color
        height: 'calc(100vh - 96px)', // subtract the height of the topNavBar
        minWidth: '200px',
    },
    button: {
        '&.MuiButton-root': {
            marginBottom: theme.spacing(1),
            //color: theme.palette.primary.contrastText,
            color: '#FFFFFF', // set the color to white
            borderColor: '#FFFFFF', // set the border color to white
            width: '100%', 
            '&:hover': {
                    borderColor: '#006dd1', // blue
            },
        },
    },
    link: {
        textDecoration: 'none', // remove the underline from the links
        display: 'flex',
    },
    landingPage: {
        fontFamily: 'Roboto, sans-serif',
        minHeight: `calc(100vh - 64px)`, // Subtract the height of the navigation bar from 100vh
        boxSizing: 'border-box',
        // color: theme.palette.text.primary,
        //background: 'linear-gradient(180deg, #003e9b 0%, #006dd1 100%)',
        background: 'linear-gradient(180deg, #333333 0%, #555555 100%)',
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            paddingTop: '5rem',
            paddingBottom: '5rem',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '7rem',
            paddingBottom: '12rem',
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: '7rem',
            paddingBottom: '12rem',
        },
        [theme.breakpoints.up('xxl')]: {
            paddingTop: '7rem',
            paddingBottom: '12rem',
        },
    },
    leftBox: {
        width: '100%',
        padding: '20px 30px 60px 30px',
        [theme.breakpoints.up('md')]: {
            padding: '0 30px 80px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '45%',
            marginLeft: '4rem',
        },
        [theme.breakpoints.up('xl')]: {
            width: '40%',
            marginLeft: '5rem',
        },
        [theme.breakpoints.up('xxl')]: {
            width: '50%',
            marginLeft: '7rem',
        },
    },
    main: {
        padding: theme.spacing(2),
    },
    hero: {
        textAlign: 'center',
        marginBottom: theme.spacing(5),
    },
    marketingCopy: {
        lineHeight: 1.5,
        textAlign: 'center',
    },
    leverage: {
        color: '#fff',
        fontSize: '1.2rem',
        fontWeight: '400',
        marginBottom: '10px',
    },
    virtualIntelligence: {
        color: '#fff',
        fontSize: '2.5rem',
        fontWeight: '700',
        marginBottom: '10px',
    },
    info: {
        color: '#fff',
        fontSize: '1.2rem',
        fontWeight: '400',
        marginBottom: '20px',
    },
    startedButton: {
        background: 'transparent',
        color: '#FFFFFF',
        padding: '10px 20px',
        borderRadius: '10px',
        cursor: 'pointer',
        '&:hover': {
            background: '#006dd1',
            color: '#fff',
        },
    },
    startedButtonText: {
        fontSize: '1.2rem',
        fontWeight: '700',
    },
    rightBox: {
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            width: '55%',
        },
    },
    phoneContainer: {
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            width: '80%',
        },
    },
    phoneImage: {
        width: '100%',
        borderRadius: '10px',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },

}));

export default useStyles;