import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import useStyles from '../globalStyles'; // Import the styles

function LeftNavBar() {
  const classes = useStyles();

  return (
    <div className={classes.leftNavBar}>
      <Link to="/my-subscriptions" className={classes.link}>
        <Button variant="outlined" color="default" className={classes.button}>List</Button>
      </Link>
      <Link to="/calendar-view" className={classes.link}>
        <Button variant="outlined" color="default" className={classes.button}>Calendar</Button>
      </Link>
      <Link to="/modify-subscription" className={classes.link}>
        <Button variant="outlined" color="default" className={classes.button}>Add</Button>
      </Link>
      <Link to="/find-services" className={classes.link}>
        <Button variant="outlined" color="default" className={classes.button}>Find</Button>
      </Link>
      <Link to="/about" className={classes.link}>
        <Button variant="outlined" color="default" className={classes.button}>About</Button>
      </Link>
      <Link to="https://forms.gle/XyKzDDsA72ys9YbN9" target="_blank" className={classes.link}>
        <Button variant="outlined" color="default" className={classes.button}>Feedback</Button>
      </Link>
    </div>
  );
}

export default LeftNavBar;
