import React from 'react';
import { useAuth0 } from '@auth0/auth0-react'; // Import the useAuth0 hook
import useStyles from '../globalStyles'; // Import the styles

const heroImage = '/Subscription.png'; // Use the public URL


const LandingPage = () => {
    const classes = useStyles();

    return (
        <div className={classes.flexColRelative}>
            <div className={`${classes.landingPage} ${classes.flexRow}`}> 
                <div className={`${classes.leftBox} ${classes.flexColCenter}`}>
                    {/* <p className={classes.leverage}>Simplify your life</p> */}
                    <h1 className={classes.virtualIntelligence}>
                        Having trouble keeping track of when all your subscription payments come out?
                    </h1>
                    <p className={classes.info}>
                        Want to know how much you spend on subscriptions in a year?
                    </p>
                    <p className={classes.info}>
                        Manage all your subscriptions and find all the subscriptions you need in one place.
                    </p>
                    <p className={classes.info}>
                        No fees ever! No credit card required!
                    </p>
                    {/* <button 
                            className={`${classes.startedButton} ${classes.flexCenter}`}
                            onClick={() => loginWithRedirect({ redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL })} // Add the onClick handler to the button
                        >
                            <p className={classes.startedButtonText}>Get Started</p>
                    </button> */}
                </div>
                <div className={`${classes.rightBox} ${classes.flexColCenter}`}>
                    <div className={`${classes.phoneContainer} ${classes.flexRowCenter}`}>
                        <img
                            className={classes.phoneImage}
                            src={heroImage} // Use the public URL as the src
                            alt=""
                        />
                    </div>
                </div>
            
        </div>
    </div>
        
    );
};

export default LandingPage;
