import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Toolbar, Typography, Button, Grid } from '@material-ui/core';
import useStyles from '../globalStyles'; 
import logo from '../thesubmanapp-favicon-white.png'; 

const staging = process.env.REACT_APP_STAGING === 'true';

const TopNavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const classes = useStyles();

  if (isAuthenticated) {
    if (staging) { 
      console.log(user.sub); 
    }
    localStorage.setItem('user', JSON.stringify(user));
  }

  return (
    <AppBar position="static" className={classes.topNavBar}>
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
            <a href={process.env.REACT_APP_BASE_URL} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
              <img src={logo} alt="Logo" className={classes.logo} />
              <Typography variant="h6">TheSubManApp.com</Typography>
            </a>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              {!isAuthenticated && (
                <Grid item>
                  <Button variant="outlined" color="inherit" onClick={() => loginWithRedirect({ screen_hint: 'signup', redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL })}>Sign Up</Button>
                </Grid>
              )}
              {isAuthenticated && (
                <Grid item>
                  <Typography variant="subtitle1">
                    Welcome, {user.given_name}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                {isAuthenticated ? (
                  <Button variant="outlined" color="inherit" onClick={() => logout()}>Logout</Button>
                ) : (
                  <Button variant="outlined" color="inherit" onClick={() => loginWithRedirect({ redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL })}>Sign In</Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavBar;