import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './views/landingPage';
import MySubscriptions from './views/mySubscriptions';
import FindServices from './views/findServices';
import ModifySubscription from './views/modifySubscription';
import CalendarView from './views/calendarView';
import About from './views/about';
import './global.css';
import TopNavBar from './components/top-nav-bar'; // Import the TopNavBar component
import { useAuth0 } from '@auth0/auth0-react';

const staging = process.env.REACT_APP_STAGING === 'true';


const App = ({ isMobile }) => {
  const { isAuthenticated, user } = useAuth0();

  if (isAuthenticated && staging) {
    console.log(user.sub);
  }

  if (staging) {
    console.log('Is mobile:', isMobile); // Log isMobile for testing
  }

  return (
      <Router> 
        <div className="app-container">
          <TopNavBar isAuthenticated={isAuthenticated} user={user}/>
          <div className="main-content">
            <Routes>
              <Route path="/" exact element={<LandingPage />} />
              {isAuthenticated && (
                <>
                  <Route path="/my-subscriptions" exact element={<MySubscriptions user={user}/>} />
                  <Route path="/calendar-view" element={<CalendarView  user={user}/>} />
                  <Route path="/find-services" element={<FindServices user={user}/>} />      
                  <Route path="/modify-subscription/:subscriptionId?" element={<ModifySubscription user={user}/>} />
                  <Route path="/about" element={<About />} />
                </>
              )}
            </Routes>
          </div>
        </div>
      </Router>
  );
};

export default App;