import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    subscriptionBar: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        width: '100%',
        boxSizing: 'border-box',
        textDecoration: 'none',
        color: 'inherit',
        border: '1px solid lightgray',
        borderRadius: '10px',
        marginBottom: '10px',
    },
    subscriptionLogo: {
        width: '50px',
        height: '50px',
        marginRight: '10px',
    },
    subscriptionInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    subscriptionName: {
        fontSize: '1.5em',
        paddingLeft: '10px',
        flex: 1,
    },
    dateContainer: {
        textAlign: 'right',
        marginLeft: 'auto',
        paddingRight: '20px',
        flex: 1,
    },
    subscriptionDate: {
        fontSize: '1.5em',
        color: 'gray',
    },
    costContainer: {
        textAlign: 'right',
        marginLeft: 'auto',
        paddingRight: '20px',
        flex: 1,
    },
    subscriptionCost: {
        fontSize: '1.5em',
        color: 'gray',
    },
});

const SubscriptionBar = ({ subscriptionName, formattedDate, subscriptionId, subscriptionFrequency, subscriptionCost }) => {
    const classes = useStyles();

    return (
        <Link to={`/modify-subscription/${subscriptionId}`} className={classes.subscriptionBar}>
            {/* <img src={logo} alt="logo" className={classes.subscriptionLogo} /> */}
            <div className={classes.subscriptionInfo}>
                <h2 className={classes.subscriptionName}>{subscriptionName}</h2>
                <div className={classes.dateContainer}>
                    <h2 className={classes.subscriptionDate}>{formattedDate}</h2>
                </div>
                <div className={classes.costContainer}>
                    <h2 className={classes.subscriptionCost}>
                        ${subscriptionCost} {
                            (() => {
                                switch (subscriptionFrequency) {
                                    case "Yearly": return "/yr";
                                    case "Monthly": return "/mo";
                                    //case "Weekly": return "/wk";
                                    default: return "";
                                }
                            })()
                        }
                    </h2>
                </div>
            </div>
        </Link>
    );
};

export default SubscriptionBar;