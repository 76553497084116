import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, makeStyles, Grid, Button, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import LeftNavBar from '../components/leftNavBar';


const baseUrl = process.env.REACT_APP_API_GATEWAY_URL;
const staging = process.env.REACT_APP_STAGING === 'true';


const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    textField: {
        fontSize: '1.5em',
    },
    formControl: {
        minWidth: 120,
        // fontSize: '1.5em',
        '& .MuiOutlinedInput-input': {
            fontSize: '1.5em',
        },
    },
    datePicker: {
        '& .MuiOutlinedInput-input': {
            fontSize: '1.5em',
        },
    },
}));


const ModifySubscription = ({ user }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [subscription, setSubscription] = useState({ userId: user.sub, subscriptionName: '', subscriptionDate: new Date(), subscriptionCost: '', subscriptionFrequency: '' });
    const { subscriptionId } = useParams();

    // get the details of the subscription to be modified
    useEffect(() => {
        if (subscriptionId) {
            axios.get(`${baseUrl}/subscriptions/${subscriptionId}`)
                .then(res => {
                    setSubscription(res.data);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [subscriptionId]);

    const handleInputChange = (event) => {
        setSubscription({
            ...subscription,
            [event.target.name]: event.target.value
        });
    };

    const handleDateChange = (date) => {
        setSubscription({
            ...subscription,
            subscriptionDate: date
        });
    };

    const handleSave = () => {
        if (subscriptionId) {
            // update existing subscription
            return axios.put(`${baseUrl}/subscriptions`, subscription)
                .then(res => {
                    if (staging) {
                        console.log(res.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            // create new subscription
            return axios.post(`${baseUrl}/subscriptions`, subscription)
                .then(res => {
                    if (staging) {
                        console.log(res.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };

    const handleDelete = () => {
        return axios.delete(`${baseUrl}/subscriptions/${subscriptionId}`)
            .then(res => {
                if (staging) {
                    console.log(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <div style={{ display: 'flex' }}>
            <LeftNavBar />
            <div style={{ display: 'flex', width: '100%' ,flexDirection: 'column', margin: '10px' }}>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name="subscriptionName"
                                variant="outlined"
                                required
                                fullWidth
                                id="subscriptionName"
                                label="Subscription Name"
                                value={subscription.subscriptionName}
                                onChange={handleInputChange}
                                InputProps={{
                                    classes: {
                                        input: classes.textField,
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="subscriptionCost"
                                variant="outlined"
                                required
                                fullWidth
                                id="subscriptionCost"
                                label="Subscription Cost"
                                value={subscription.subscriptionCost}
                                onChange={handleInputChange}
                                InputProps={{
                                    classes: {
                                        input: classes.textField,
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                <InputLabel id="subscriptionFrequency-label">Subscription Frequency</InputLabel>
                                <Select
                                    labelId="subscriptionFrequency-label"
                                    id="subscriptionFrequency"
                                    value={subscription.subscriptionFrequency}
                                    onChange={handleInputChange}
                                    label="Subscription Frequency"
                                    name="subscriptionFrequency"
                                >
                                    <MenuItem value={"Monthly"}>Monthly</MenuItem>
                                    <MenuItem value={"Yearly"}>Yearly</MenuItem>
                                    {/* <MenuItem value={"Weekly"}>Weekly</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    name="subscriptionDate"
                                    variant="inline"
                                    inputVariant="outlined"
                                    id="subscriptionDate"
                                    label="Subscription Date"
                                    value={subscription.subscriptionDate}
                                    onChange={handleDateChange}
                                    fullWidth
                                    className={classes.datePicker}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={3}>
                                <Button
                                    style={{ marginLeft: '8px' }}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        handleSave().then(() => navigate('/my-subscriptions'));
                                    }}
                                >
                                    Save
                                </Button>
                            </Grid>
                            {subscriptionId && (
                                <Grid item xs={3} container justifyContent="flex-end">
                                    <Button
                                        style={{ marginRight: '8px' }}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDelete().then(() => navigate('/my-subscriptions'));
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </form>
                {/* Rest of the view here */}
            </div>
        </div>
    );
};

export default ModifySubscription;