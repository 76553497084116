import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import LeftNavBar from '../components/leftNavBar';
import { makeStyles } from '@material-ui/core/styles'; // Import makeStyles
import Calendar from '../components/calendar';

const baseUrl = process.env.REACT_APP_API_GATEWAY_URL;
const staging = process.env.REACT_APP_STAGING === 'true';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: `calc(100vw - 280px)`, 
        height: `calc(100vh - 180px)`, 
        boxSizing: 'border-box',
    },
    calendarSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        marginLeft: '20px',
    },
    calendarContainer: {
        // width: `calc(100% - 270px)`, // subtract total gap space
        // height: `calc(100% - 140px)`, // subtract total gap space
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
    },
    monthTitle: {
        fontSize: '2em',
        textAlign: 'center',
        marginTop: theme.spacing(1),
    },
}));

const CalendarView = ({ user }) => {
    const classes = useStyles(); // Call useStyles to get classes
    const [subscriptions, setSubscriptions] = useState([]);
    const calendarContainerRef = useRef(null);
    const [calendarSize, setCalendarSize] = useState({ width: 0, height: 0 });
    const currentMonth = new Date().toLocaleString('default', { month: 'long' });

    useEffect(() => {
        const updateSize = () => {
            setCalendarSize({
                width: calendarContainerRef.current.offsetWidth,
                height: calendarContainerRef.current.offsetHeight,
            });
        };

        window.addEventListener('resize', updateSize);
        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        axios.get(`${baseUrl}/subscriptions/?userId=${user.sub}`)
            .then(res => {
                if (staging) {
                    console.log("subscriptions from GET in calendarView", res.data);
                }
                const subscriptionsWithFormattedDate = res.data.map(subscription => {
                    const date = new Date(subscription.subscriptionDate);
                    
                    let formattedDate;
                    
                    if (subscription.subscriptionFrequency === "Yearly") {
                        formattedDate = `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}`;
                    } else if (subscription.subscriptionFrequency === "Monthly") {
                        const currentDate = new Date();
                        formattedDate = `${currentDate.toLocaleString('default', { month: 'long' })} ${date.getDate()}`;
                    }
                        
                    return { ...subscription, formattedDate };
                });
    
                const sortedSubscriptions = subscriptionsWithFormattedDate.sort((a, b) => {
                    return new Date(a.formattedDate) - new Date(b.formattedDate);
                });
    
                setSubscriptions(sortedSubscriptions);
            })
            .catch(err => {
                console.log(err);
            });
    }, [user.sub]);



    return (
        <div className={classes.container}>
            <LeftNavBar />
            <div className={classes.calendarSection}>
                <div className={classes.monthTitle}>{currentMonth}</div>
                <div ref={calendarContainerRef} className={classes.calendarContainer}>
                    <Calendar width={calendarSize.width} height={calendarSize.height} subscriptions={subscriptions}/>
                </div>
            </div>    
        </div>
    );
}

export default CalendarView;